import { NBadge, NTag, type BadgeProps, type TagProps } from 'naive-ui'
import { Icon } from '#components'

export function renderStatusTag(params: {
  label: () => string
  color: string
  type: 'tag' | 'dot'
  size?: 'small' | 'medium' | 'large'
  dotPlacement?: 'left' | 'right'
  tagProps?: TagProps
  badgeProps?: BadgeProps
}) {
  if (params.type === 'dot')
    return (
      <div class={['flex items-center gap-2 w-fit', { 'flex-row-reverse': params.dotPlacement === 'right' }]}>
        <NBadge
          {...params.badgeProps ?? {}}
          color={params.color}
          dot
          class="!z-1"
        />
        {renderVNode(params.label)}
      </div>
    )

  return (
    <NTag
      {...params.tagProps ?? {}}
      class="!z-1"
    >
      <div class={['flex items-center gap-2 w-fit', { 'flex-row-reverse': params.dotPlacement === 'right' }]}>
        <NBadge
          {...params.badgeProps ?? {}}
          color={params.color}
          dot
          class="!z-1"
        />
        {renderVNode(params.label)}
      </div>

    </NTag>
  )
}

export function renderBoolean(
  value: boolean,
  params?: {
    mode?: 'icon' | 'tag'
    size?: 'small' | 'medium' | 'large'
    trueLabel?: string | (() => string)
    falseLabel?: string | (() => string)
  }
) {
  const { $i18n } = useNuxtApp()
  if ((params?.mode ?? 'icon') === 'icon')
    return (
      <Icon name={value ? 'mdi:check' : 'mdi:close'} class={value ? 'text-brand-success' : 'text-brand-error'} />
    )
  return (
    <NTag class="w-fit" type={value ? 'success' : 'error'} size={params?.size ?? 'small'}>
      {value
        ? typeof params?.trueLabel === 'function' ? params.trueLabel() : params?.trueLabel ?? $i18n.t('labels.yes')
        : typeof params?.falseLabel === 'function' ? params.falseLabel() : params?.falseLabel ?? $i18n.t('labels.no')}
    </NTag>
  )
}
